.am-fade-and-slide-top {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-fade-and-slide-top.am-fade-and-slide-top-add,
.am-fade-and-slide-top.ng-hide-remove,
.am-fade-and-slide-top.ng-move {
  animation-name: fadeAndSlideFromTop;
}
.am-fade-and-slide-top.am-fade-and-slide-top-remove,
.am-fade-and-slide-top.ng-hide {
  animation-name: fadeAndSlideToTop;
}
.am-fade-and-slide-top.ng-enter {
  visibility: hidden;
  animation-name: fadeAndSlideFromTop;
  animation-play-state: paused;
}
.am-fade-and-slide-top.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-fade-and-slide-top.ng-leave {
  animation-name: fadeAndSlideToTop;
  animation-play-state: paused;
}
.am-fade-and-slide-top.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-fade-and-slide-right {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-fade-and-slide-right.am-fade-and-slide-right-add,
.am-fade-and-slide-right.ng-hide-remove,
.am-fade-and-slide-right.ng-move {
  animation-name: fadeAndSlideFromRight;
}
.am-fade-and-slide-right.am-fade-and-slide-right-remove,
.am-fade-and-slide-right.ng-hide {
  animation-name: fadeAndSlideToRight;
}
.am-fade-and-slide-right.ng-enter {
  visibility: hidden;
  animation-name: fadeAndSlideFromRight;
  animation-play-state: paused;
}
.am-fade-and-slide-right.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-fade-and-slide-right.ng-leave {
  animation-name: fadeAndSlideToRight;
  animation-play-state: paused;
}
.am-fade-and-slide-right.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-fade-and-slide-bottom {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-fade-and-slide-bottom.am-fade-and-slide-bottom-add,
.am-fade-and-slide-bottom.ng-hide-remove,
.am-fade-and-slide-bottom.ng-move {
  animation-name: fadeAndSlideFromBottom;
}
.am-fade-and-slide-bottom.am-fade-and-slide-bottom-remove,
.am-fade-and-slide-bottom.ng-hide {
  animation-name: fadeAndSlideToBottom;
}
.am-fade-and-slide-bottom.ng-enter {
  visibility: hidden;
  animation-name: fadeAndSlideFromBottom;
  animation-play-state: paused;
}
.am-fade-and-slide-bottom.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-fade-and-slide-bottom.ng-leave {
  animation-name: fadeAndSlideToBottom;
  animation-play-state: paused;
}
.am-fade-and-slide-bottom.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-fade-and-slide-left {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-fade-and-slide-left.am-fade-and-slide-left-add,
.am-fade-and-slide-left.ng-hide-remove,
.am-fade-and-slide-left.ng-move {
  animation-fill-mode: backwards;
  animation-name: fadeAndSlideFromLeft;
}
.am-fade-and-slide-left.am-fade-and-slide-left-remove,
.am-fade-and-slide-left.ng-hide {
  animation-name: fadeAndSlideToLeft;
}
.am-fade-and-slide-left.ng-enter {
  visibility: hidden;
  animation-name: fadeAndSlideFromLeft;
  animation-play-state: paused;
}
.am-fade-and-slide-left.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-fade-and-slide-left.ng-leave {
  animation-name: fadeAndSlideToLeft;
  animation-play-state: paused;
}
.am-fade-and-slide-left.ng-leave.ng-leave-active {
  animation-play-state: running;
}
@keyframes fadeAndSlideFromTop {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeAndSlideToTop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-20%);
  }
}
@keyframes fadeAndSlideFromRight {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeAndSlideToRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(20%);
  }
}
@keyframes fadeAndSlideFromBottom {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeAndSlideToBottom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(20%);
  }
}
@keyframes fadeAndSlideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeAndSlideToLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-20%);
  }
}
