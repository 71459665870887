
// Fade & Slide
//

@fade-and-slide-duration: .3s;
@fade-and-slide-timing-function: ease-in-out;

.am-fade-and-slide-top {

  animation-duration: @fade-and-slide-duration;
  animation-timing-function: @fade-and-slide-timing-function;
  animation-fill-mode: backwards;

  &.am-fade-and-slide-top-add, &.ng-hide-remove, &.ng-move {
    animation-name: fadeAndSlideFromTop;
  }
  &.am-fade-and-slide-top-remove, &.ng-hide {
    animation-name: fadeAndSlideToTop;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: fadeAndSlideFromTop;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: fadeAndSlideToTop;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}

.am-fade-and-slide-right {

  animation-duration: @fade-and-slide-duration;
  animation-timing-function: @fade-and-slide-timing-function;
  animation-fill-mode: backwards;

  &.am-fade-and-slide-right-add, &.ng-hide-remove, &.ng-move {
    animation-name: fadeAndSlideFromRight;
  }
  &.am-fade-and-slide-right-remove, &.ng-hide {
    animation-name: fadeAndSlideToRight;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: fadeAndSlideFromRight;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: fadeAndSlideToRight;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}

.am-fade-and-slide-bottom {

  animation-duration: @fade-and-slide-duration;
  animation-timing-function: @fade-and-slide-timing-function;
  animation-fill-mode: backwards;

  &.am-fade-and-slide-bottom-add, &.ng-hide-remove, &.ng-move {
    animation-name: fadeAndSlideFromBottom;
  }
  &.am-fade-and-slide-bottom-remove, &.ng-hide {
    animation-name: fadeAndSlideToBottom;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: fadeAndSlideFromBottom;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: fadeAndSlideToBottom;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}

.am-fade-and-slide-left {

  animation-duration: @fade-and-slide-duration;
  animation-timing-function: @fade-and-slide-timing-function;
  animation-fill-mode: backwards;

  &.am-fade-and-slide-left-add, &.ng-hide-remove, &.ng-move {
    animation-fill-mode: backwards;
    animation-name: fadeAndSlideFromLeft;
  }
  &.am-fade-and-slide-left-remove, &.ng-hide {
    animation-name: fadeAndSlideToLeft;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: fadeAndSlideFromLeft;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: fadeAndSlideToLeft;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}


// Keyframes
//

@keyframes fadeAndSlideFromTop {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAndSlideToTop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-20%);
  }
}

@keyframes fadeAndSlideFromRight {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeAndSlideToRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(20%);
  }
}

@keyframes fadeAndSlideFromBottom {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAndSlideToBottom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(20%);
  }
}

@keyframes fadeAndSlideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAndSlideToLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-20%);
  }
}
