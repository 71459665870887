.am-flip-x {
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
}
.am-flip-x.am-flip-x-add,
.am-flip-x.ng-hide-remove,
.am-flip-x.ng-move {
  animation-name: flipInXBounce;
}
.am-flip-x.am-flip-x-remove,
.am-flip-x.ng-hide {
  animation-name: flipOutX;
}
.am-flip-x.ng-enter {
  visibility: hidden;
  animation-name: flipInXBounce;
  animation-play-state: paused;
}
.am-flip-x.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-flip-x.ng-leave {
  animation-name: flipOutX;
  animation-play-state: paused;
}
.am-flip-x.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-flip-x-linear {
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
}
.am-flip-x-linear.am-flip-x-add,
.am-flip-x-linear.ng-hide-remove,
.am-flip-x-linear.ng-move {
  animation-name: flipInX;
}
.am-flip-x-linear.am-flip-x-remove,
.am-flip-x-linear.ng-hide {
  animation-name: flipOutX;
}
.am-flip-x-linear.ng-enter {
  visibility: hidden;
  animation-name: flipInX;
  animation-play-state: paused;
}
.am-flip-x-linear.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-flip-x-linear.ng-leave {
  animation-name: flipOutX;
  animation-play-state: paused;
}
.am-flip-x-linear.ng-leave.ng-leave-active {
  animation-play-state: running;
}
@keyframes flipInX {
  from {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  to {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
}
@keyframes flipInXBounce {
  from {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  to {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
}
@keyframes flipOutX {
  from {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
  to {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
}
