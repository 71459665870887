.am-slide-top {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-slide-top.am-slide-top-add,
.am-slide-top.ng-hide-remove,
.am-slide-top.ng-move {
  animation-name: slideFromTop;
}
.am-slide-top.am-slide-top-remove,
.am-slide-top.ng-hide {
  animation-name: slideToTop;
}
.am-slide-top.ng-enter {
  visibility: hidden;
  animation-name: slideFromTop;
  animation-play-state: paused;
}
.am-slide-top.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-slide-top.ng-leave {
  animation-name: slideToTop;
  animation-play-state: paused;
}
.am-slide-top.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-slide-right {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-slide-right.am-slide-right-add,
.am-slide-right.ng-hide-remove,
.am-slide-right.ng-move {
  animation-name: slideFromRight;
}
.am-slide-right.am-slide-right-remove,
.am-slide-right.ng-hide {
  animation-name: slideToRight;
}
.am-slide-right.ng-enter {
  visibility: hidden;
  animation-name: slideFromRight;
  animation-play-state: paused;
}
.am-slide-right.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-slide-right.ng-leave {
  animation-name: slideToRight;
  animation-play-state: paused;
}
.am-slide-right.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-slide-bottom {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-slide-bottom.am-slide-bottom-add,
.am-slide-bottom.ng-hide-remove,
.am-slide-bottom.ng-move {
  animation-name: slideFromBottom;
}
.am-slide-bottom.am-slide-bottom-remove,
.am-slide-bottom.ng-hide {
  animation-name: slideToBottom;
}
.am-slide-bottom.ng-enter {
  visibility: hidden;
  animation-name: slideFromBottom;
  animation-play-state: paused;
}
.am-slide-bottom.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-slide-bottom.ng-leave {
  animation-name: slideToBottom;
  animation-play-state: paused;
}
.am-slide-bottom.ng-leave.ng-leave-active {
  animation-play-state: running;
}
.am-slide-left {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-slide-left.am-slide-left-add,
.am-slide-left.ng-hide-remove,
.am-slide-left.ng-move {
  animation-name: slideFromLeft;
}
.am-slide-left.am-slide-left-remove,
.am-slide-left.ng-hide {
  animation-name: slideToLeft;
}
.am-slide-left.ng-enter {
  visibility: hidden;
  animation-name: slideFromLeft;
  animation-play-state: paused;
}
.am-slide-left.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-slide-left.ng-leave {
  animation-name: slideToLeft;
  animation-play-state: paused;
}
.am-slide-left.ng-leave.ng-leave-active {
  animation-play-state: running;
}
@keyframes slideFromTop {
  from {
    transform: translateY(-100%);
  }
}
@keyframes slideToTop {
  to {
    transform: translateY(-100%);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }
}
@keyframes slideToRight {
  to {
    transform: translateX(100%);
  }
}
@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
  }
}
@keyframes slideToBottom {
  to {
    transform: translateY(100%);
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }
}
@keyframes slideToLeft {
  to {
    transform: translateX(-100%);
  }
}
