
// Fade & Slide
//

@fade-and-scale-duration: .3s;
@fade-and-scale-timing-function: ease-in-out;

.am-fade-and-scale {

  animation-duration: @fade-and-scale-duration;
  animation-timing-function: @fade-and-scale-timing-function;
  animation-fill-mode: backwards;

  &.ng-enter, &.am-fade-and-scale-add, &.ng-hide-remove, &.ng-move {
    animation-name: fadeAndScaleIn;
  }
  &.ng-leave, &.am-fade-and-scale-remove, &.ng-hide {
    animation-name: fadeAndScaleOut;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: fadeAndScaleIn;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: fadeAndScaleOut;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}


// Keyframes
//

@keyframes fadeAndScaleIn {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAndScaleOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.7);
  }
}
