.am-fade-and-scale {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
.am-fade-and-scale.ng-enter,
.am-fade-and-scale.am-fade-and-scale-add,
.am-fade-and-scale.ng-hide-remove,
.am-fade-and-scale.ng-move {
  animation-name: fadeAndScaleIn;
}
.am-fade-and-scale.ng-leave,
.am-fade-and-scale.am-fade-and-scale-remove,
.am-fade-and-scale.ng-hide {
  animation-name: fadeAndScaleOut;
}
.am-fade-and-scale.ng-enter {
  visibility: hidden;
  animation-name: fadeAndScaleIn;
  animation-play-state: paused;
}
.am-fade-and-scale.ng-enter.ng-enter-active {
  visibility: visible;
  animation-play-state: running;
}
.am-fade-and-scale.ng-leave {
  animation-name: fadeAndScaleOut;
  animation-play-state: paused;
}
.am-fade-and-scale.ng-leave.ng-leave-active {
  animation-play-state: running;
}
@keyframes fadeAndScaleIn {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeAndScaleOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.7);
  }
}
