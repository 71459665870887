
// Collapse
//

@collapse-duration: .3s;
@collapse-timing-function: ease;

.am-collapse {

  animation-duration: @collapse-duration;
  animation-timing-function: @collapse-timing-function;
  animation-fill-mode: backwards;
  overflow: hidden;
  opacity: 1;

  &.am-collapse-add, &.ng-hide-remove, &.ng-move  {
    animation-name: expand;
  }
  &.am-collapse-remove, &.ng-hide {
    animation-name: collapse;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: expand;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: collapse;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}


// Keyframes
//

@keyframes expand {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

@keyframes collapse {
  from {
    max-height: 500px;
  }
  to {
    max-height: 0px;
  }
}

// Bootstrap 3
//

.panel-collapse {

  &.am-collapse {

    &.in-remove {
      animation-name: collapse;
      display: block;
    }
    &.in-add {
      animation-name: expand;
    }

  }

}
