

// Fade
//

@flip-duration: .4s;
@flip-timing-function: ease;

.am-flip-x {

  animation-duration: @flip-duration;
  animation-timing-function: @flip-timing-function;
  animation-fill-mode: backwards;

  &.am-flip-x-add, &.ng-hide-remove, &.ng-move {
    animation-name: flipInXBounce;
  }
  &.am-flip-x-remove, &.ng-hide {
    animation-name: flipOutX;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: flipInXBounce;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: flipOutX;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}

.am-flip-x-linear {

  animation-duration: @flip-duration;
  animation-timing-function: @flip-timing-function;
  animation-fill-mode: backwards;

  &.am-flip-x-add, &.ng-hide-remove, &.ng-move {
    animation-name: flipInX;
  }
  &.am-flip-x-remove, &.ng-hide {
    animation-name: flipOutX;
  }

  &.ng-enter {
    visibility: hidden;
    animation-name: flipInX;
    animation-play-state: paused;
    &.ng-enter-active {
      visibility: visible;
      animation-play-state: running;
    }
  }
  &.ng-leave {
    animation-name: flipOutX;
    animation-play-state: paused;
    &.ng-leave-active {
      animation-play-state: running;
    }
  }

}

// Keyframes
//

@keyframes flipInX {
  from {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  to {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
}

@keyframes flipInXBounce {
  from {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  to {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
}

@keyframes flipOutX {
  from {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
  to {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
}
